export default function ButtonLogout({setUser})
{
    const handleLogout = () =>
    {
        setUser(
            prevState => (
                {
                    ...prevState,
                    "user": null,
                    "isLogged": false
                }
            )
        );
    }
    return(<><button type="button" className="btn btn-danger" onClick={handleLogout}>LOGOUT</button></>)
}