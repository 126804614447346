import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function ButtonEntraLobbyNavbar({ setUser }) {
    const [isCreatingLobby, setIsCreatingLobby] = useState(false);
    const [datierrati, setDatierrati] = useState(false);
    const [errors, setErrors] = useState([]);
    const closeButtonRef = useRef(null);
    const navigate = useNavigate();

    const handleFormLobby = async (e) => {
        e.preventDefault(); // Previene il ricaricamento della pagina

        const newErrors = [];

        const lobbyNomeRegex = /[A-z]+/;
        if (isCreatingLobby && !lobbyNomeRegex.test(e.target.elements.lobbyNome.value)) {
            newErrors.push("Il nome della stanza può avere solo le lettere.");
        }

        const lobbyPlayersRegex = /[0-9]+/;
        if (isCreatingLobby && !lobbyPlayersRegex.test(e.target.elements.lobbyPlayers.value)) {
            newErrors.push("Inserire un numero valido.");
        }

        if (isCreatingLobby && Number(e.target.elements.lobbyPlayers.value) < 6 && Number(e.target.elements.lobbyPlayers.value.length) > 28) {
            newErrors.push("I player devono essere minimo 6 o massimo 28.");
        }

        const lobbyIdRegex = /[0-9]+/;
        if (!isCreatingLobby && !lobbyIdRegex.test(e.target.elements.lobbyId.value)) {
            newErrors.push("Inserire un numero valido.");
        }

        if (!isCreatingLobby && e.target.elements.lobbyId.value.length < 3) {
            newErrors.push("L'ID non può essere minore di 4.");
        }

        if (newErrors.length > 0) {
            setErrors(newErrors);
            setDatierrati(true);
        } else {
            if (isCreatingLobby) {
                const request = {
                    "nome": e.target.elements.lobbyNome.value,
                    "numeroPartecipanti": e.target.elements.lobbyPlayers.value,
                    "link": window.location.hostname
                };

                try {
                    const res = await axios.post(`http://${window.location.hostname}:8081/lobby`, request, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    // Gestione della risposta
                    if (res.data.ok) {
                        setErrors([]);
                        setDatierrati(false);
                        setUser( prevState => ({
                            ...prevState,
                            isAdmin: true
                        }) );
                        navigate(`/lobby?idLobby=${res.data.idStanza}`);
                        // Chiudi il modal automaticamente
                        if (closeButtonRef.current) {
                            closeButtonRef.current.click();
                        }
                    } else {
                        setErrors([res.data.msg]);
                        setDatierrati(true);
                    }

                } catch (error) {
                    if (error.response) {
                        // Il server ha risposto con uno stato diverso da 2xx
                        setErrors([error.response.data.msg]);
                    } else if (error.request) {
                        // La richiesta è stata fatta ma non c'è stata alcuna risposta
                        setErrors(['Nessuna risposta dal server. Verificare la connessione.']);
                    } else {
                        // Qualcosa è andato storto nell'impostazione della richiesta
                        setErrors(['Errore durante l’invio dei dati']);
                    }

                    setDatierrati(true);
                }
            }
            else {
                try {
                    const res = await axios.get(`http://${window.location.hostname}:8081/lobby?idStanza=${e.target.elements.lobbyId.value}&link=${window.location.hostname}`);

                    // Gestione della risposta
                    if (res.data.ok) {
                        setErrors([]);
                        setDatierrati(false);
                        navigate(`/lobby?idLobby=${e.target.elements.lobbyId.value}`);
                        // Chiudi il modal automaticamente
                        if (closeButtonRef.current) {
                            closeButtonRef.current.click();
                        }
                    } else {
                        setErrors([res.data.msg]);
                        setDatierrati(true);
                    }

                } catch (error) {
                    if (error.response) {
                        // Il server ha risposto con uno stato diverso da 2xx
                        setErrors([error.response.data.msg]);
                    } else if (error.request) {
                        // La richiesta è stata fatta ma non c'è stata alcuna risposta
                        setErrors(['Nessuna risposta dal server. Verificare la connessione.']);
                    } else {
                        // Qualcosa è andato storto nell'impostazione della richiesta
                        setErrors(['Errore durante l’invio dei dati']);
                    }

                    setDatierrati(true);
                }
            }
        }
    }

    const resetForm = () => {
        // Resetta i campi di input usando il DOM direttamente
        const lobbyNomeInput = document.getElementById('lobbyNome');
        const lobbyPlayersInput = document.getElementById('lobbyPlayers');
        const lobbyIdInput = document.getElementById('lobbyId');

        if (lobbyNomeInput) lobbyNomeInput.value = '';
        if (lobbyPlayersInput) lobbyPlayersInput.value = '';
        if (lobbyIdInput) lobbyIdInput.value = '';
        setDatierrati(false); // Resetta anche eventuali errori
        setErrors([]); // Resetta eventuali messaggi d'errore
    };

    const handleChangeLobbyMode = () => {
        resetForm();
        setIsCreatingLobby(!isCreatingLobby);
    };

    return (
        <>
            {/* Modal per entrare in una stanza */}
            <div className="modal fade" data-bs-backdrop="static" id="entraLobbyNavbarModal" aria-hidden="true" aria-labelledby="entraLobbyNavbarModal" tabIndex="-1" style={{ "backdrop-filter": "blur(5px)" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ backgroundColor: "rgba(0, 0, 0, 0.66)", color: "white", boxShadow: "inset black 0 0 82px, white 0 0 3px" }}>
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="titleModalLabel">{isCreatingLobby ? ("Crea la partita") : ("Entra in partita")}</h1>
                            <button type="button" ref={closeButtonRef} className="btn btn-close btn-danger bg-danger" data-bs-dismiss="modal" aria-label="Close" onClick={resetForm}></button>
                        </div>
                        {
                            datierrati ? (
                                <>
                                    {
                                        errors && errors.map((item, index) => (
                                            <div class="alert alert-danger alert-dismissible fade show d-flex align-items-center m-3" role="alert" key={index}>
                                                <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Danger:" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" width="24" height="24">
                                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                                </svg>
                                                <div>
                                                    {item}
                                                    {/* <button type="button" class="btn-close" data-bs-dismiss="alert" onClick={() => removeError(index)} aria-label="Close"></button> */}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </>
                            ) : null
                        }
                        {
                            isCreatingLobby ? (<form onSubmit={handleFormLobby}>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <label htmlFor="lobbyid" className="form-label">🔤 Nome Stanza</label>
                                        <input type="text" className="form-control" id="lobbyNome" style={{ backgroundColor: "white", color: "black" }} required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="lobbyid" className="form-label">👥 Numero players MAX</label>
                                        <input type="text" className="form-control" id="lobbyPlayers" style={{ backgroundColor: "white", color: "black" }} required />
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={resetForm}>Chiudi</button>
                                    {/* Ternario per scegliere tra entrare in una stanza o creare una */}
                                    {
                                        !isCreatingLobby ? (
                                            <button type="submit" className="btn btn-success">Entra</button>
                                        ) : (
                                            <button type="submit" className="btn btn-success">Crea una nuova stanza</button>
                                        )
                                    }
                                </div>
                                <div className="text-center">
                                    {/* Pulsante per cambiare modalità */}
                                    <button
                                        type="button"
                                        className="btn btn-link text-light"
                                        onClick={handleChangeLobbyMode}
                                    >
                                        {!isCreatingLobby ? 'oppure crea una nuova stanza' : 'torna ad entrare in una stanza'}
                                    </button>
                                </div>
                            </form>) : (<form onSubmit={handleFormLobby}>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <label htmlFor="lobbyid" className="form-label">🆔 Stanza</label>
                                        <input type="text" className="form-control" id="lobbyId" style={{ backgroundColor: "white", color: "black" }} required />
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={resetForm}>Chiudi</button>
                                    {/* Ternario per scegliere tra entrare in una stanza o creare una */}
                                    {
                                        !isCreatingLobby ? (
                                            <button type="submit" className="btn btn-success">Entra</button>
                                        ) : (
                                            <button type="submit" className="btn btn-success">Crea una nuova stanza</button>
                                        )
                                    }
                                </div>
                                <div className="text-center">
                                    {/* Pulsante per cambiare modalità */}
                                    <button
                                        type="button"
                                        className="btn btn-link text-light"
                                        onClick={handleChangeLobbyMode}
                                    >
                                        {!isCreatingLobby ? 'oppure crea una nuova stanza' : 'torna ad entrare in una stanza'}
                                    </button>
                                </div>
                            </form>)
                        }
                    </div>
                </div>
            </div>

            {/* Pulsante per aprire il modal */}
            <button type="button" className="btn btn-danger ms-2" data-bs-toggle="modal" data-bs-target="#entraLobbyNavbarModal">
                Entra in cittadella 🏰
            </button>
        </>
    );
}