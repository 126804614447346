import ButtonRegistrazione from "../navbar/ButtonRegistrazione";
import ButtonLogin from "../navbar/ButtonLogin";
import ButtonEntraLobbyNavbar from "../navbar/ButtonEntraLobby";

export default function Cards({ user, setUser }) {
  let cards;
  if (user.isLogged) {
    cards =
      <div class="col-sm-6 mb-3 mb-sm-0">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Entra nella cittadella</h5>
            <p class="card-text">Entra in una lobby in fase di preparazione!</p>
            <ButtonEntraLobbyNavbar setUser={setUser}/>
          </div>
        </div>
      </div>;
  }
  else {
    cards =
      <div class="row">
        <div class="col-sm-6 mb-3 mb-sm-0">
          <div class="card h-100">
            <div class="card-body">
              <h5 class="card-title">Login</h5>
              <p class="card-text">Se vuoi entrare nel tuo profilo, loggati con il pulsante qui sotto!</p>
              <ButtonLogin setUser={setUser} />
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="card h-100">
            <div class="card-body">
              <h5 class="card-title">Regitrati</h5>
              <p class="card-text">Se vuoi creare il tuo profilo, registrati con il pulsante qui sotto!</p>
              <ButtonRegistrazione setUser={setUser} />
            </div>
          </div>
        </div>
      </div>;
  }
  return (
    <>
      {cards}
    </>
  )
}