import { useState, useEffect } from "react";
import axios from "axios";
import mqtt from 'mqtt';
import { useLocation, useNavigate } from "react-router-dom";
import ButtonRuoloPopup from "./ButtonRuoloPopup";

export default function Game({ user, setUser }) {
    const navigate = useNavigate();  // Usa useNavigate per il redirect
    const [errors, setErrors] = useState(null);
    const [partita, setPartita] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [idGame, setIdGame] = useState(queryParams.get('idGame'));
    const [updateError, setUpdateError] = useState(false);

    console.log("id corrente ", sessionStorage.getItem('currentPlayerId') );

    // Funzione per rimuovere un errore dopo un timeout
    useEffect(() => {
        if (errors && errors.length > 0) {
            const timer = setTimeout(() => {
                setErrors(prevErrors => prevErrors.slice(1)); // Rimuove il primo errore
            }, 3000); // 3 secondi

            return () => clearTimeout(timer); // Pulizia del timeout quando il componente si smonta o errors si aggiorna
        }
    }, [errors]);

    useEffect(() => {
        // Connetti al broker MQTT
        const client = mqtt.connect(`ws://${window.location.hostname}:9001`, {
            reconnectPeriod: 1000, // Riconnessione automatica ogni 1 secondo
            protocol: 'mqtt'
        });

        client.on('connect', () => {
            if (client.connected) {
                client.subscribe('/game/' + idGame, { qos: 1 }, (err) => {
                    if (err) {
                        console.error('Errore durante la sottoscrizione:', err);
                    } else {
                        <></>
                    }
                });
            }
        });

        client.on('message', (topic, message) => {
            if (topic === '/game/' + idGame) {
                console.log('Messaggio ricevuto:', message.toString());
                if (message.toString().length === 0) {
                    navigate('/');
                }
                else {
                    const partita = JSON.parse(message.toString());
                    setPartita(partita);
                }

            }
        });

        client.on('error', (err) => {
            console.error('Errore di connessione MQTT:', err);
        });

        // Pulizia quando il componente viene disattivato
        return () => {
            client.end();
        };
    }, []);

    const aggiornaStatoMqtt = async (idPlayer, dato, valore, forzato) => {
        const request = {
            "idGame": idGame,
            "dato": dato,
            "valore": valore,
            "isForzato": forzato ? (forzato) : (false)
        };

        console.log("send request ", `http://${window.location.hostname}:8081/game/player/${idPlayer}/status`, request)

        try {
            const res = await axios.patch(`http://${window.location.hostname}:8081/game/player/${idPlayer}/status`, request, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            console.log('Risposta dal server:', res.data);

            // Gestione della risposta
            if (res.data && !res.data.ok) {
                setErrors([res.data.msg]);
            }

        } catch (error) {
            setUpdateError(true);
            if (error.response) {
                // Il server ha risposto con uno stato diverso da 2xx
                console.error('Errore nella richiesta POST - Risposta dal server:', error.response.data);
                setErrors([error.response.data.msg]);
            } else if (error.request) {
                // La richiesta è stata fatta ma non c'è stata alcuna risposta
                console.error('Errore nella richiesta POST - Nessuna risposta dal server:', error.request);
                setErrors(['Nessuna risposta dal server. Verificare la connessione.']);
            } else {
                // Qualcosa è andato storto nell'impostazione della richiesta
                console.error('Errore nella richiesta POST:', error.message);
                setErrors(['Errore durante l’invio dei dati']);
            }
        }
    }

    const handleCambioNota = (e, idPlayer) => {
        e.preventDefault(); // Previene il ricaricamento della pagina
        const input = e.target.elements.notaAggiuntivaGame.value;
        console.log("la nuova nota ha questo input ", input);
        aggiornaStatoMqtt(idPlayer, "players.note", input, true);
    };

    const handleStatoVita = (e, idPlayer) => {
        e.preventDefault();
        const input = e.target.innerHTML;
        aggiornaStatoMqtt(idPlayer, "players.status", input, true);
    };

    const handleIncrementoVoto = (e, voto, idPlayer) => {
        e.preventDefault();
        const input = voto + 1;
        console.log("input voto ", input);
        aggiornaStatoMqtt(idPlayer, "players.votes", input, true);
    };

    const handleDecrementoVoto = (e, voto, idPlayer) => {
        e.preventDefault();
        const input = voto - 1;
        console.log("input voto ", input);
        aggiornaStatoMqtt(idPlayer, "players.votes", input, true);
    };

    const handleChangeVillageHead = (e, stato, idPlayer) => {
        e.preventDefault();
        const input = !stato;
        aggiornaStatoMqtt(idPlayer, "players.villageHead", input, true);
    };

    const handleProssimaFase = (e) => {
        e.preventDefault();
        aggiornaStatoMqtt(0, "giorno.fase", "", true);
    };

    const handleTerminaGame = async () => {
        try {
            const res = await axios.delete(`http://${window.location.hostname}:8081/game`, {
                data: { "idGame": idGame },
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // Gestione della risposta
            if (res.data.ok) {
                sessionStorage.removeItem('currentPlayerId');
                setUser(
                    prevState => (
                        {
                            ...prevState,
                            "isAdmin": false
                        }
                    )
                );
            }
            else {
                setErrors([res.data.msg]);
            };

        } catch (error) {
            setUpdateError(true);
            if (error.response) {
                // Il server ha risposto con uno stato diverso da 2xx
                setErrors([error.response.data.msg]);
            } else if (error.request) {
                // La richiesta è stata fatta ma non c'è stata alcuna risposta
                setErrors(['Nessuna risposta dal server. Verificare la connessione.']);
            } else {
                // Qualcosa è andato storto nell'impostazione della richiesta
                setErrors(['Errore durante l’invio dei dati']);
            }
        }
    };

    return (
        <>
            {
                errors ? (
                    <>
                        {
                            errors.map((item, index) => (
                                <div class="alert alert-danger alert-dismissible fade show d-flex align-items-center m-3" role="alert" key={index}>
                                    <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Danger:" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" width="24" height="24">
                                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                    </svg>
                                    <div>
                                        {item}
                                    </div>
                                </div>
                            ))
                        }
                    </>
                ) : null
            }
            <center>
                <div className='row'>
                    <div className='col-12'>
                        <div class="card text-bg-dark w-100" style={{ "width": "18rem" }}>
                            <div class="card-body sticky-top" style={{ "backdropFilter": "blur(8px)" }}>
                                <div className="row">
                                    <div className="col-6 d-flex justify-content-center align-items-center" style={{ "fontSize": "29px" }}>
                                        {"Giorno " + (partita && partita.giorno && partita.giorno.numero ? (partita.giorno.numero) : ("sconosciuto"))}
                                    </div>
                                    <div className="col-6 d-flex justify-content-center align-items-center" style={{ "fontSize": "70px" }}>
                                        {
                                            partita && partita.giorno && partita.giorno.fase ? (partita.giorno.fase) : ("sconosciuto")
                                        }
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item text-bg-dark">
                                    <div className='row'>
                                        <div className='col-2 d-flex justify-content-center align-items-center'>username</div>
                                        <div className='col-1 d-flex justify-content-center align-items-center'>ruolo</div>
                                        <div className='col-1 d-flex justify-content-center align-items-center'>capo villaggio</div>
                                        <div className='col-2 d-flex justify-content-center align-items-center'>stato vita</div>
                                        <div className='col-1 d-flex justify-content-center align-items-center'>voti</div>
                                        <div className='col-4 d-flex justify-content-center align-items-center'>note aggiuntive</div>
                                    </div>
                                </li>
                                {
                                    partita && partita.players && partita.players.length > 0 && partita.players.map((player, index) => {
                                        return (
                                            <>
                                                {
                                                    <li class="list-group-item text-bg-dark" key={index} id={index}>
                                                        <div className='row'>
                                                            <div className='col-2 d-flex justify-content-center align-items-center'>{player.username}</div>
                                                            <div className='col-1 d-flex justify-content-center align-items-center'>
                                                                {
                                                                    user.isAdmin ? (
                                                                        <ButtonRuoloPopup nomeRuolo={player.role} setErrors={setErrors} />
                                                                    ):(
                                                                        partita.giorno.fase === "🌕" ? (
                                                                            <p style={{ "filter": "blur(3px)" }}>??????????</p>
                                                                        ):(
                                                                            parseInt(sessionStorage.getItem('currentPlayerId')) === index + 1 || player.isDead ? (
                                                                                <ButtonRuoloPopup nomeRuolo={player.role} setErrors={setErrors} />
                                                                            ):(
                                                                                <p style={{ "filter": "blur(3px)" }}>??????????</p>
                                                                            )
                                                                        )
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='col-1 d-flex justify-content-center align-items-center'>
                                                                <div class="form-check">
                                                                    {
                                                                        !user.isAdmin ? (
                                                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={player.villageHead} />
                                                                        ) : (
                                                                            player.isDead ? (<input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={player.villageHead} disabled />) : (<input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" onClick={(e) => handleChangeVillageHead(e, player.villageHead, index)} checked={player.villageHead} />)
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='col-2 d-flex justify-content-center align-items-center'>
                                                                {
                                                                    user.isAdmin ? (
                                                                        <>
                                                                            <button class="btn btn-danger dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                {player.status}
                                                                            </button>
                                                                            <form>
                                                                                <ul class="dropdown-menu">
                                                                                    <li><button type="submit" onClick={(e) => handleStatoVita(e, index)} class="dropdown-item">👨🏻‍🌾 - Vivo</button></li>
                                                                                    <li><button type="submit" onClick={(e) => handleStatoVita(e, index)} id="inputStatoVita" class="dropdown-item">⚰️ - Morto</button></li>
                                                                                    <li><button type="submit" onClick={(e) => handleStatoVita(e, index)} id="inputStatoVita" class="dropdown-item">🗳️ - Votato</button></li>
                                                                                </ul>
                                                                            </form>
                                                                        </>
                                                                    ):( partita.giorno.fase != "🌕" ? (player.status):(<p style={{ "filter": "blur(3px)" }}>?</p>) )
                                                                }
                                                            </div>
                                                            <div className='col-1 g-1 d-flex justify-content-center align-items-center'>
                                                                {
                                                                    player.isDead || partita && partita.giorno && partita.giorno.fase !== "🗳️" ? (player.votes) : (
                                                                        <div className="row">
                                                                            <div className="col col-4 d-flex justify-content-center align-items-center">{player.votes}</div>
                                                                            <div className="col col-4 d-flex justify-content-center align-items-center"><button onClick={(e) => handleIncrementoVoto(e, player.votes, index)} className="btn btn-light">+</button></div>
                                                                            <div className="col col-4 d-flex justify-content-center align-items-center"><button onClick={(e) => handleDecrementoVoto(e, player.votes, index)} className="btn btn-light">-</button></div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='col-4 d-flex justify-content-center align-items-center'>
                                                                <div className="row">
                                                                    {
                                                                        !user.isAdmin ? (
                                                                            <>
                                                                                <div className='col-10 d-flex justify-content-center align-items-center'>Note del master</div>
                                                                                <div className='col-2 d-flex justify-content-center align-items-center'>🤫</div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div className='col-8 d-flex justify-content-center align-items-center'>{player.note}</div>
                                                                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                                                                    <div class="dropdown w-100">
                                                                                        <button type="button" class="btn btn-danger dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="true">📝</button>
                                                                                        <div className="dropdown-menu p-4">
                                                                                            {
                                                                                                <form onSubmit={(e) => handleCambioNota(e, index)}>
                                                                                                    <div class="mb-3">
                                                                                                        <label for="username" class="form-label">Nota aggiuntiva</label>
                                                                                                        <input type="text" class="form-control" id="notaAggiuntivaGame" style={{ "background-color": "white" }} required />
                                                                                                    </div>
                                                                                                    <button type="submit" class="btn btn-success">Salva</button>
                                                                                                </form>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                }
                                            </>
                                        )
                                    })
                                }
                            </ul>
                            <div class="card-body">
                                <div className='row'>
                                    {
                                        user.isAdmin ? (
                                            <>
                                                <div className='col-1'>
                                                    <button type="button" class="btn btn-danger" onClick={handleTerminaGame}>Termina</button>
                                                </div>
                                                <div className='col-7'>
                                                    {/* {
                                                        user.isAdmin && partita && partita.giorno && partita.giorno.fase === "🗳️" ? (
                                                            <button className="btn btn-success">Fine votazione</button>
                                                        ) :
                                                        (
                                                            partita && partita.giorno && partita.giorno.isLastTurn ? (
                                                                <span class="badge text-bg-warning">ULTIMO TURNO!!!</span>
                                                            ) : (
                                                                partita && partita.giorno && partita.giorno.isEnded ? (
                                                                    <span class="badge text-bg-success">Partita terminana</span>
                                                                ) : (
                                                                    null
                                                                )
                                                            )
                                                        )
                                                    } */}
                                                </div>
                                                <div className='col-3'>
                                                    {
                                                        partita && partita.giorno && !partita.giorno.isEnded ? (
                                                            <button type="button" class="btn btn-light" onClick={(e) => handleProssimaFase(e)}>Prossima fase ➡️</button>
                                                        ) : (
                                                            <button type="button" class="btn btn-light" disabled>Prossima fase ➡️</button>
                                                        )
                                                    }
                                                </div>
                                            </>
                                        ) : (null)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </center>
        </>
    )
}