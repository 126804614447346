import { useState, useEffect } from "react";
import axios from "axios";


function Regole() {
  const [regole, setRegole] = useState(null)
  const [response, setResponse] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    const getTable = async (e) => {

      try {
        const res = await axios.get(`http://${window.location.hostname}:8081/roles`);

        setResponse(res.data);  // Imposta la risposta nel tuo stato
        console.log('Risposta dal server:', res.data);

        // Gestione della risposta
        if (res.data.ok) {
          setErrors([]);
          setRegole(res.data.ruoli);
        } else {
          setErrors([res.data.msg]);
        }

      } catch (error) {
        if (error.response) {
          // Il server ha risposto con uno stato diverso da 2xx
          console.error('Errore nella richiesta GET - Risposta dal server:', error.response.data);
          setResponse({ msg: error.response.data.msg ? error.response.data.msg : 'Errore durante l’invio dei dati' });
          setErrors([error.response.data.msg]);
        } else if (error.request) {
          // La richiesta è stata fatta ma non c'è stata alcuna risposta
          console.error('Errore nella richiesta GET - Nessuna risposta dal server:', error.request);
          setResponse({ msg: 'Nessuna risposta dal server. Verificare la connessione.' });
          setErrors(['Nessuna risposta dal server. Verificare la connessione.']);
        } else {
          // Qualcosa è andato storto nell'impostazione della richiesta
          console.error('Errore nella richiesta GET:', error.message);
          setResponse({ msg: 'Errore durante l’invio dei dati' });
          setErrors(['Errore durante l’invio dei dati']);
        }
      }

    }
    getTable();
  }, []);

  return (
    <>
      {
        errors && errors.map((item, index) => (
          <div class="alert alert-danger alert-dismissible fade show d-flex align-items-center m-3" role="alert" key={index}>
            <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Danger:" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" width="24" height="24">
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
            <div>
              {item}
              {/* <button type="button" class="btn-close" data-bs-dismiss="alert" onClick={() => removeError(index)} aria-label="Close"></button> */}
            </div>
          </div>
        ))
      }
      {
        !regole && (
          <div class="d-flex justify-content-center">
            <div class="spinner-border text-danger" style={{ "width": "3rem", "height": "3rem" }} role="status">
              <span class="visually-hidden">Caricamento...</span>
            </div>
          </div>
        )
      }

      {
        regole && (
          <div class="row row-cols-1 row-cols-sm-1 row-cols-md-4 row-cols-lg-7 g-3">
            {regole.map((item, index) => (
              <div class="col">
                <div class="card text-bg-dark  h-100">
                  <div class="card-header pb-0 mb-0"><h4>{item.nome}</h4></div>
                  <img src={`data:image/${item.estenzione};base64,${item.foto}`} class="card-img-top m-0 p-5 pb-2" alt={`${item.nome}`} />
                  <center><hr class="w-50 mt-4"/></center>
                  <div class="card-body">
                    <p class="card-text pt-0">{item.descrizione}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )
      }
    </>
  );
}

export default Regole;