import axios from "axios";
import { useState } from "react";


export default function ButtonRuoloPopup({ nomeRuolo, setErrors }) {

    const [descrizione, setDescrizione] = useState("");

    const getRuolo = async (e) => {
        try {
            const res = await axios.get(`http://${window.location.hostname}:8081/roles?campi=descrizione&filter=nome=${nomeRuolo}`);

            // Gestione della risposta
            if (res.data.ok) {
                setDescrizione(res.data.ruoli[0].descrizione)
            } else {
                setErrors([res.data.msg]);
            }

        } catch (error) {
            if (error.response) {
                setErrors([error.response.data.msg]);
            } else if (error.request) {
                setErrors(['Nessuna risposta dal server. Verificare la connessione.']);
            } else {
                setErrors(['Errore durante l’invio dei dati']);
            }
        }
    }

    return (
        <>
            <div class="modal fade" id="gameModalDescrizioneRuolo" tabindex="-1" aria-labelledby="gameModalDescrizioneRuolo" aria-hidden="true" style={{ "backdrop-filter": "blur(5px)" }}>
                <div class="modal-dialog modal-dialog-scrollable">
                    <div class="modal-content" style={{ "background-color": "rgba(0, 0, 0, 0.66)", "color": "white", "box-shadow": "inset black 0 0 82px, white 0 0 3px" }}>
                        <div class="modal-header">
                            <h2 class="modal-title">Descrizione {nomeRuolo}</h2>
                            <button type="button" class="btn btn-close btn-danger bg-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p>{descrizione}</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Chiudi</button>
                        </div>
                    </div>
                </div>
            </div>

            <button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#gameModalDescrizioneRuolo" onClick={getRuolo}>
                {nomeRuolo}
            </button>
        </>
    )
}