import { useEffect, useState } from "react";
import axios from "axios";
import RoleSelection from "./RoleSelector";

export default function RigaTabellaPlayer({ isCurrentPlayerId, user, player, idPlayer, setErrors, lobby }) {
    const [updateError, setUpdateError] = useState(false);
    const [done, setDone] = useState(true);

    useEffect(() => {
        if( !user.isAdmin && user.isLogged )
        {
            aggiornaStatoMqtt([{"dato": "players.username", "valore": user.user.username, "isForzato": true}]);
        }
    },[done]);

    const aggiornaStatoMqtt = (datiDaAggiornare) => {
        const request = {
            "idStanza": lobby,
            "data": datiDaAggiornare
        };
        try {
            const res = axios.patch(`http://${window.location.hostname}:8081/lobby/player/${idPlayer - 1}/status`, request, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            console.log("response aggiorna stato mqtt di riga tabella player ", res);

            // Gestione della risposta
            if (res.data && !res.data.ok) {
                setErrors([res.data.msg]);
            }

        } catch (error) {
            setUpdateError(true);
            if (error.response) {
                // Il server ha risposto con uno stato diverso da 2xx
                setErrors([error.response.data.msg]);
            } else if (error.request) {
                // La richiesta è stata fatta ma non c'è stata alcuna risposta
                setErrors(['Nessuna risposta dal server. Verificare la connessione.']);
            } else {
                // Qualcosa è andato storto nell'impostazione della richiesta
                setErrors(['Errore durante l’invio dei dati aggiorna stato mqtt ' + error ]);
            }
        }
    }

    const handleCambioUsername = (e) => {
        e.preventDefault(); // Previene il ricaricamento della pagina
        const nuovoUsername = e.target.elements.usernameLobby.value;
        aggiornaStatoMqtt([{"dato": "players.username", "valore": nuovoUsername, "isForzato": false}])
        if (!updateError) {
            const formUsername = document.getElementById('usernameLobby');
            if (formUsername) formUsername.value = '';
        }
    };

    console.log("username corrente ", player.username);

    return (
        <>
            <>
                <li class="list-group-item text-bg-dark" key={idPlayer} id={idPlayer}>
                    <div className='row'>
                        <div className='col-4 d-flex justify-content-center align-items-center'>
                            {
                                user.isAdmin || !isCurrentPlayerId ? (player.username) : (
                                    <div class="dropdown w-100">
                                        <button type="button" class="btn btn-danger dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="true">
                                            {
                                                player.username
                                            }
                                        </button>
                                        <div className="dropdown-menu p-4">
                                            {
                                                user.isLogged ? (<span class="badge text-bg-success">Sei già loggato</span>) : (
                                                    <form onSubmit={handleCambioUsername}>
                                                        <div class="mb-3">
                                                            <label for="username" class="form-label">Username</label>
                                                            <input type="text" class="form-control" id="usernameLobby" style={{ "background-color": "white" }} required />
                                                        </div>
                                                        <button type="submit" class="btn btn-success">Salva</button>
                                                    </form>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className='col-4 d-flex justify-content-center align-items-center'>
                            {
                                !user.isAdmin ? (
                                    isCurrentPlayerId ? (player.ruolo):(<p style={{"filter": "blur(3px)"}}>Sconosciuto</p>)
                                ) : (
                                    <RoleSelection ruoloSelezionato={player.ruolo} idLobby={lobby} idPlayer={idPlayer} setErrors={setErrors} />
                                )
                            }
                        </div>
                        <div className='col-4 d-flex justify-content-center align-items-center'>
                            {
                                player.pronto ? (
                                    <span class="badge text-bg-success">Pronto</span>
                                ) : (
                                    <div class="spinner-border text-warning" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </li>
            </>
        </>
    )
}