import axios from "axios";
import { useState, useEffect } from "react";


export default function RoleSelection({ruoloSelezionato, idPlayer, idLobby, setErrors}) {
    const [listaRuoli, setListaRuoli] = useState([]);

    useEffect(() => {
        const generaLista = async (e) => {
            try {
                const res = await axios.get(`http://${window.location.hostname}:8081/roles?filter=nome!=capo villaggio`);

                // Gestione della risposta
                if (res.data.ok) {
                    setListaRuoli(res.data.ruoli)
                } else {
                    setErrors([res.data.msg]);
                }

            } catch (error) {
                if (error.response) {
                    // Il server ha risposto con uno stato diverso da 2xx
                    setErrors([error.response.data.msg]);
                } else if (error.request) {
                    // La richiesta è stata fatta ma non c'è stata alcuna risposta
                    setErrors(['Nessuna risposta dal server. Verificare la connessione.']);
                } else {
                    // Qualcosa è andato storto nell'impostazione della richiesta
                    setErrors(['Errore durante l’invio dei dati']);
                }
            }
        }
        generaLista();
    }, []);

    const handleScelta = (nome) => {
        aggiornaStatoMqtt([{"dato": "players.pronto", "valore": true, "isForzato": true},{"dato": "players.ruolo", "valore": nome, "isForzato": true}])
    };

    const aggiornaStatoMqtt = async (datiDaAggiornare) => {
        const request = {
            "idStanza": idLobby,
            "data": datiDaAggiornare
        };
        try {
            const res = await axios.patch(`http://${window.location.hostname}:8081/lobby/player/${idPlayer - 1}/status`, request, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // Gestione della risposta
            if (!res.data.ok) {
                setErrors([res.data.msg]);
            }

        } catch (error) {
            if (error.response) {
                // Il server ha risposto con uno stato diverso da 2xx
                setErrors([error.response.data.msg]);
            } else if (error.request) {
                // La richiesta è stata fatta ma non c'è stata alcuna risposta
                setErrors(['Nessuna risposta dal server. Verificare la connessione.']);
            } else {
                // Qualcosa è andato storto nell'impostazione della richiesta
                setErrors(['Errore durante l’invio dei dati']);
            }
        }
    }

    return (<>
        <div class="dropdown w-50">
            <button type="button" class="btn btn-danger dropdown-toggle" data-bs-auto-close="inside" data-bs-toggle="dropdown" aria-expanded="false">
                {
                    ruoloSelezionato
                }
            </button>
            <ul class="dropdown-menu">
                {
                    listaRuoli.map((item, index) => (
                        <li id={item.id} key={index}>
                            <button class="dropdown-item" type="button" onClick={() => handleScelta(item.nome)}>
                                <div className="row">
                                    <span className="col-4 g-1">
                                        <img src={`data:image/${item.estenzione};base64,${item.foto}`} className="img-fluid m-0 p-0" />
                                    </span>
                                    <span className="col-8 d-flex  g-2">
                                        <p>{item.nome}</p>
                                    </span>
                                </div>
                            </button>
                        </li>
                    ))
                }
            </ul>
        </div>
    </>)
}