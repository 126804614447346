import Cards from "./Cards";

export default function Home({ user }) {
    return (
        <>
            <div className="container my-5">
                <section className="text-center">
                    <h2>🌕 Lupus in Fabula</h2>
                    <h3>Inizia a giocare e difenderti dai lupi! Oppure uccidi gli altri!</h3>
                    <p className="lead">Unisciti alla cittadella e scegli il tuo destino. Sarai un lupo spietato o un astuto cittadino?</p>
                    <div className="d-flex justify-content-center">
                        <Cards user={user} />
                    </div>
                </section>
            </div>
        </>
    );
}
