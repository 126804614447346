import { Link } from 'react-router-dom'; // Importa il componente Link
import ButtonEntraLobbyNavbar from './ButtonEntraLobby';
import ButtonLogin from './ButtonLogin';
import ButtonRegistrazione from "./ButtonRegistrazione";
import ButtonLogout from './ButtonLogout';


export default function Navbar({ user, setUser }) {
    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
                <Link className="navbar-brand text-white" to="/">
                    {/* <img src="wolf-howl-svgrepo-com.svg" alt="Logo" className="d-inline-block align-text-top" style={{"width": "50px", "height": "50px"}} /> */}
                    <b>🌕 Lupus in Fabula</b> {/* Aggiunta di una luna piena per creare un'atmosfera fiabesca */}
                </Link>
                <button className="navbar-toggler bg-danger" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link active text-danger" to="/">🏰 Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-danger" to="/regole">📜 Regole</Link> {/* Usa Link per la navigazione */}
                        </li>
                    </ul>
                    {/* <form className="d-flex mx-auto" style={{ width: "50%" }} role="search">
                        <input className="form-control me-2" type="search" placeholder="🔍 Cerca nelle regole" aria-label="Cerca" />
                        <button className="btn btn-outline-success" type="submit">Cerca</button>
                    </form> */}
                    <ul className="navbar-nav ms-auto">
                        {
                            user.isLogged ? (
                                <>
                                    <li className="me-2">Ciao {user.user.username}</li>
                                    <li className="me-2"><ButtonLogout setUser={setUser} /></li>
                                </>
                            ) : (
                                <>
                                    <li className="me-2">
                                        <ButtonLogin setUser={setUser} />
                                    </li>
                                    <li className="me-2">
                                        <ButtonRegistrazione setUser={setUser} />
                                    </li>
                                </>
                            )
                        }
                        <li><ButtonEntraLobbyNavbar setUser={setUser} /></li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}