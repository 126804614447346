import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import mqtt from 'mqtt';
import RigaTabellaPlayer from "./RigaTabellaPlayer";

export default function Lobby({ user, setUser }) {
    const navigate = useNavigate();  // Usa useNavigate per il redirect
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [idLobby, setIdLobby] = useState(queryParams.get('idLobby'));
    const [action, setAction] = useState(queryParams.get('action'));
    const [lobby, setLobby] = useState({
        "gameIsStarted": false,
        "idGame": null,
        "qrcode": "",
        "numeroPartecipanti": null,
        "nome": "",
        "players": []
    });
    // Usa sessionStorage per mantenere il currentPlayerId
    const [currentPlayerId, setCurrentPlayerId] = useState(
        sessionStorage.getItem('currentPlayerId') 
            ? parseInt(sessionStorage.getItem('currentPlayerId'), 10)
            : null
    );
    const [errors, setErrors] = useState(null);
    const [lobbyIsReady, setLobbyIsReady] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false);

    // Persistenza di currentPlayerId in sessionStorage
    useEffect(() => {
        if (currentPlayerId) {
            sessionStorage.setItem('currentPlayerId', currentPlayerId);
        }
    }, [currentPlayerId]);


    // funzione per aggiungere un player che fa una join nella lobby
    useEffect(() => {
        if (action && action === "join" && !currentPlayerId) {
            const addUtenteToLobby = async () => {
                try {
                    const res = await axios.get(`http://${window.location.hostname}:8081/lobby?idStanza=${idLobby}&link=${window.location.hostname}`);
                    console.log("response add utente to lobby ", res);

                    if (!res.data.ok) {
                        setErrors([res.data.msg]);
                    }
                } catch (error) {
                    if (error.response) {
                        setErrors([error.response.data.msg]);
                    } else if (error.request) {
                        setErrors(['Nessuna risposta dal server. Verificare la connessione.']);
                    } else {
                        setErrors(['Errore durante l’invio dei dati addUtenteToLobby']);
                    }
                }
            };
            addUtenteToLobby();
        }
    }, [action, idLobby, currentPlayerId]);

    // Funzione per copiare l'ID della lobby negli appunti
    const copyToClipboard = (text) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    setCopySuccess(true);
                    setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
                })
                .catch(err => {
                    console.error('Errore durante la copia:', err);
                });
        } else {
            // Fallback per browser più vecchi
            const textArea = document.createElement("textarea");
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                setCopySuccess(true);
                setTimeout(() => setCopySuccess(false), 2000);
            } catch (err) {
                console.error('Errore durante la copia con execCommand:', err);
            }
            document.body.removeChild(textArea);
        }
    };

    // Funzione per rimuovere un errore dopo un timeout
    useEffect(() => {
        if (errors && errors.length > 0) {
            const timer = setTimeout(() => {
                setErrors(prevErrors => prevErrors.slice(1)); // Rimuove il primo errore
            }, 3000); // 3 secondi

            return () => clearTimeout(timer); // Pulizia del timeout quando il componente si smonta o errors si aggiorna
        }
    }, [errors]);

    // funzione mqtt
    useEffect(() => {
        // Connetti al broker MQTT
        const client = mqtt.connect(`ws://${window.location.hostname}:9001`, {
            reconnectPeriod: 1000, // Riconnessione automatica ogni 1 secondo
            protocol: 'mqtt'
        });

        client.on('connect', () => {
            if (client.connected) {
                client.subscribe('/lobby/' + idLobby, { qos: 1 }, (err) => {
                    if (err) {
                        console.error('Errore durante la sottoscrizione:', err);
                    } else {
                        <></>
                    }
                });
            }
        });

        client.on('message', (topic, message) => {
            if (topic === '/lobby/' + idLobby) {
                console.log('Messaggio ricevuto:', message.toString());
                if (message.toString().length === 0) {
                    navigate('/');
                }
                else {
                    const jsonParasato = JSON.parse(message.toString());
                    setLobby(jsonParasato);
                    if (!currentPlayerId) {
                        setCurrentPlayerId(lobby.players.length);
                    };

                    // Controlla se tutti i giocatori selezionati sono pronti
                    const allPlayersReady = jsonParasato.players.slice(0, parseInt(jsonParasato.numeroPartecipanti)).every(player => player.pronto);

                    if( jsonParasato.players.length === 0 )
                    {
                        setLobbyIsReady(false);
                    }
                    else {
                        setLobbyIsReady(allPlayersReady);
                    };

                    if (jsonParasato.idGame) {
                        navigate('/game?idGame=' + jsonParasato.idGame);
                    }
                }
            }
        });

        client.on('error', (err) => {
            console.error('Errore di connessione MQTT:', err);
        });

        // Pulizia quando il componente viene disattivato
        return () => {
            client.end();
        };
    }, [currentPlayerId]);

    // funzione popup per il messaggio Copiato!
    useEffect(() => {
        // Inizializzare i popover dopo che il componente è montato
        const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
        const popoverList = [...popoverTriggerList].map(popoverTriggerEl => {
            const popover = new window.bootstrap.Popover(popoverTriggerEl);

            // Aggiungere l'evento per far scomparire il popover dopo 2 secondi
            popoverTriggerEl.addEventListener('shown.bs.popover', () => {
                setTimeout(() => {
                    popover.hide();
                }, 1000); // Nasconde il popover dopo 2 secondi
            });

            return popover;
        });

        return () => {
            // Pulire i popover quando il componente viene smontato
            popoverList.forEach(popover => popover.dispose());
        };
    }, []);

    // funzione di avvio game
    const handleStartGame = async (e) => {
        try {
            const request = {
                "idStanza": idLobby
            }

            const res = await axios.post(`http://${window.location.hostname}:8081/game`, request, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // Gestione della risposta
            if (!res.data.ok) {
                setErrors([res.data.msg]);
            }

        } catch (error) {
            if (error.response) {
                // Il server ha risposto con uno stato diverso da 2xx
                setErrors([error.response.data.msg]);
            } else if (error.request) {
                // La richiesta è stata fatta ma non c'è stata alcuna risposta
                setErrors(['Nessuna risposta dal server. Verificare la connessione.']);
            } else {
                // Qualcosa è andato storto nell'impostazione della richiesta
                setErrors(['Errore durante l’invio dei dati start game']);
            }
        }
    }

    const handleAnnulla = async (e) => {
        try {
            const res = await axios.delete(`http://${window.location.hostname}:8081/lobby`, {
                data: { "idStanza": idLobby },
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // Gestione della risposta
            if (res.data.ok) {
                sessionStorage.removeItem('currentPlayerId');
                setUser(
                    prevState => (
                        {
                            ...prevState,
                            "isAdmin": false
                        }
                    )
                );
                navigate('/');
            }
            else {
                setErrors([res.data.msg]);
            }

        } catch (error) {
            if (error.response) {
                // Il server ha risposto con uno stato diverso da 2xx
                setErrors([error.response.data.msg]);
            } else if (error.request) {
                // La richiesta è stata fatta ma non c'è stata alcuna risposta
                setErrors(['Nessuna risposta dal server. Verificare la connessione.']);
            } else {
                // Qualcosa è andato storto nell'impostazione della richiesta
                setErrors(['Errore durante l’invio dei dati annulla']);
            }
        }
    }

    return (
        <>
            <div class="modal fade" id="modalQrCode" tabindex="-1" aria-labelledby="modalQrCode" aria-hidden="true" style={{ "backdrop-filter": "blur(5px)" }}>
                <div class="modal-dialog">
                    <div class="modal-content" style={{ backgroundColor: "rgba(0, 0, 0, 0.66)", color: "white", boxShadow: "inset black 0 0 82px, white 0 0 3px" }}>
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">qr code della stanza</h1>
                            <button type="button" className="btn btn-close btn-danger bg-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <img
                                className='m-0'
                                src={`data:image/png;base64,${lobby.qrcode}`}
                                style={{ height: "300px", width: "300px" }}
                                alt="QR Code"
                            />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {
                errors ? (
                    <>
                        {
                            errors.map((item, index) => (
                                <div class="alert alert-danger alert-dismissible fade show d-flex align-items-center m-3" role="alert" key={index}>
                                    <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Danger:" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" width="24" height="24">
                                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                    </svg>
                                    <div>
                                        {item}
                                    </div>
                                </div>
                            ))
                        }
                    </>
                ) : null
            }
            <center>
                <div className='row'>
                    <div className='col-12'>
                        <div class="card text-bg-dark w-100" style={{ "width": "18rem" }}>
                            <div class="card-body sticky-top" style={{ "backdropFilter": "blur(8px)" }}>
                                <h5 class="card-title" >{lobby.nome}</h5>
                                <hr />
                                <div className='row'>
                                    <div className='col-6 d-flex justify-content-center align-items-center'>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            style={{ "color": "white" }}
                                            data-bs-container="body"
                                            data-bs-toggle="popover"
                                            data-bs-placement="bottom"
                                            data-bs-content="Copiato!"
                                            onClick={() => copyToClipboard(idLobby)}
                                        >
                                            {idLobby}
                                        </button>
                                    </div>
                                    <div className='col-6 d-flex justify-content-center align-items-center'>
                                        <button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#modalQrCode">
                                            <img
                                                className='m-0'
                                                src={`data:image/png;base64,${lobby.qrcode}`}
                                                style={{ height: "50px", width: "50px" }}
                                                alt="QR Code"
                                            />
                                        </button>
                                    </div>
                                </div>
                                <div className='row row-cols-3 g-2 mt-1'>
                                    <div className='col-6 d-flex justify-content-center align-items-center'>
                                        <span class="badge text-bg-light">{(lobby.players.length + 1 > lobby.numeroPartecipanti ? (lobby.numeroPartecipanti) : (lobby.players.length)) + "/" + lobby.numeroPartecipanti + " players"}</span>
                                    </div>
                                    <div className='col-6 d-flex justify-content-center align-items-center'>
                                        {
                                            (lobby.players.length < lobby.numeroPartecipanti) ? (
                                                <span class="badge text-bg-warning">In attesa di altri players</span>
                                            ) : (
                                                !lobbyIsReady ? (<span class="badge text-bg-warning">In attesa dei ruoli</span>) : (<span class="badge text-bg-success">Siamo pronti a partire</span>)
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <ul class="list-group list-group-flush">
                                {
                                    lobby.players.length > 0 && lobby.players.map((player, index) => {
                                        return (
                                            <>
                                                {
                                                    index < lobby.numeroPartecipanti ? (<>
                                                        <RigaTabellaPlayer lobby={idLobby} isCurrentPlayerId={currentPlayerId > lobby.numeroPartecipanti ? (false) : (currentPlayerId === (index + 1))} user={user} player={player} idPlayer={index + 1} setErrors={setErrors} />
                                                    </>) : (null)
                                                }
                                            </>
                                        )
                                    })
                                }
                                {
                                    (lobby.players.length < lobby.numeroPartecipanti) ? (
                                        <li class="list-group-item text-bg-dark">
                                            <div className='row'>
                                                <div className='col-6 d-flex justify-content-center align-items-center'>
                                                    <p>in attesa di altri players...</p>
                                                </div>
                                                <div className='col-4 d-flex justify-content-center align-items-center'>
                                                    <div class="spinner-grow text-warning" role="status">
                                                        <span class="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>) : (null)
                                }
                            </ul>
                            <div class="card-body">
                                <div className='row'>
                                    {
                                        user.isAdmin ? (
                                            <>
                                                <div className='col-6'>
                                                    <button type="button" class="btn btn-danger" onClick={handleAnnulla}>Annulla</button>
                                                </div>
                                                <div className='col-6'>
                                                    {lobbyIsReady ? (<button type="button" class="btn btn-success" onClick={handleStartGame}>Avvia</button>) : (<button type="button" class="btn btn-success" disabled>Avvia</button>)}
                                                </div>
                                            </>
                                        ) : (null)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </center>
        </>
    );
}
