import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Importa React Router
import Navbar from './components/navbar/Navbar';
import Regole from './components/Regole';
import Home from './components/home/Home';
import Game from './components/game/Game';
import Lobby from './components/lobby/Lobby';
import { useState, useEffect } from "react";

function App() {
  // Inizializza user dallo sessionStorage, se esiste, o imposta i valori di default
  const [user, setUser] = useState(() => {
    const savedUser = sessionStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : { "user": null, "isLogged": false, "isAdmin": false };
  });

  // Aggiorna sessionStorage ogni volta che lo stato user cambia
  useEffect(() => {
    sessionStorage.setItem('user', JSON.stringify(user));
  }, [user]);

  return (
    <Router>
      <div className="App">
        <Navbar user={user} setUser={setUser} />
        <main className="container">
          <Routes>
            <Route path="/" element={<Home user={user} />} />
            <Route path="/regole" element={<Regole />} /> {/* Definisci la route per le regole */}
            <Route path="/lobby" element={<Lobby user={user} setUser={setUser} />} />
            <Route path="/game" element={<Game user={user} setUser={setUser} />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;