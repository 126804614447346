import { useState, useRef } from "react";
import CryptoJS from "crypto-js";
import axios from "axios";

export default function ButtonLogin({ setUser }) {
    const [datierrati, setDatierrati] = useState(false);
    const [errors, setErrors] = useState([]);
    const closeButtonRef = useRef(null);

    const handleLogin = async (e) => {
        e.preventDefault(); // Previene il ricaricamento della pagina

        const formLoginUsername = e.target.elements.formLoginUsername.value;
        const formLoginPassword = e.target.elements.formLoginPassword.value;

        {
            // Se non ci sono errori, invia il form
            const request = {
                "username": formLoginUsername,
                "password": CryptoJS.SHA256(formLoginPassword).toString(CryptoJS.enc.Hex)
            };

            try {
                const res = await axios.post(`http://${window.location.hostname}:8081/login`, request, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                // Gestione della risposta
                if (res.data.ok) {
                    setErrors([]);
                    setDatierrati(false);

                    setUser(
                        prevState => (
                            {
                                ...prevState,
                                "user":
                                {
                                    "username": formLoginUsername
                                },
                                "isLogged": true
                            }
                        )
                    );

                    if (closeButtonRef.current) {
                        closeButtonRef.current.click();
                    }

                } else {
                    setErrors([res.data.msg]);
                    setDatierrati(true);
                }

            } catch (error) {
                if (error.response) {
                    // Il server ha risposto con uno stato diverso da 2xx
                    setErrors([error.response.data.msg]);
                } else if (error.request) {
                    // La richiesta è stata fatta ma non c'è stata alcuna risposta
                    setErrors(['Nessuna risposta dal server. Verificare la connessione.']);
                } else {
                    // Qualcosa è andato storto nell'impostazione della richiesta
                    setErrors(['Errore durante l’invio dei dati']);
                }
                setDatierrati(true);
            }
        }
    }

    const resetForm = () => {
        if (document.getElementById('formLoginUsername')) document.getElementById('formLoginUsername').value= '';
        if (document.getElementById('formLoginPassword')) document.getElementById('formLoginPassword').value= '';

        setDatierrati(false); // Resetta anche eventuali errori
        setErrors([]); // Resetta eventuali messaggi d'errore
    };

    return (
        <>
            <div class="modal fade" id="loginModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="loginModal" aria-hidden="true" style={{ "backdrop-filter": "blur(5px)" }}>
                <div class="modal-dialog modal-lg">
                    <div class="modal-content" style={{ "background-color": "rgba(0, 0, 0, 0.66)", "color": "white", "box-shadow": "inset black 0 0 82px, white 0 0 3px" }}>
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">login</h1>
                            <button type="button" ref={closeButtonRef} class="btn btn-close btn-danger bg-danger" data-bs-dismiss="modal" onClick={resetForm} aria-label="Close"></button>
                        </div>
                        {
                            datierrati ? (
                                <>
                                    {
                                        errors && errors.map((item, index) => (
                                            <div class="alert alert-danger alert-dismissible fade show d-flex align-items-center m-3" role="alert" key={index}>
                                                <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Danger:" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" width="24" height="24">
                                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                                </svg>
                                                <div>
                                                    {item}
                                                    {/* <button type="button" class="btn-close" data-bs-dismiss="alert" onClick={() => removeError(index)} aria-label="Close"></button> */}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </>
                            ) : null
                        }

                        <form onSubmit={handleLogin}>
                            <div className="modal-body">
                                <div className="mb-3 row">
                                    <div className="col-md-6">
                                        <label htmlFor="username" className="form-label">👤 Nome utente</label>
                                        <input type="text" className="form-control" id="formLoginUsername" style={{ backgroundColor: "white", color: "black" }} required />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="password" className="form-label">🔐 Password</label>
                                        <input type="password" className="form-control" id="formLoginPassword" style={{ backgroundColor: "white", color: "black" }} required />
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" ref={closeButtonRef} className="btn btn-danger" data-bs-dismiss="modal" onClick={resetForm}>Chiudi</button>
                                <button type="submit" className="btn btn-success">Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#loginModal">Login</button>
        </>
    );
}